






























































import Vue from "vue";
import PageHeading from "@/components/PageHeading.vue";
import ACForm from "@/components/form/ACForm.vue";
import { mapGetters } from "vuex";
import ModalContainer from "@/components/ModalContainer.vue";

export default Vue.extend({
  name: "UserProfile",
  components: { PageHeading, ACForm, ModalContainer },
  props: {
    title: { type: String },
  },
  computed: {
    ...mapGetters({
      user: "auth/authUser",
    }),
    showFeature(): boolean {
      if (process.env.VUE_APP_SHOW_FEATURE !== "true") {
        return false;
      }

      return true;
    },
  },
});
