



























import Vue from "vue";
import { apiAxios } from "@/store/apiAxios";

export default Vue.extend({
  name: "ACForm",
  data: () => ({
    note: "",
  }),
  props: {
    user: { required: true },
  },
  methods: {
    onSubmit() {
      apiAxios
        .post("/api/user/ac-form", {
          note: this.note,
          reltype: "Subscriber",
        })
        .then((r) => {
          console.log(r);
          if (r.data === "success") {
            this.note = "";
            this.$bvModal.show("modal-message-sent");
          } else {
            this.$bvModal.show("modal-message-failed");
          }
        });
    },
  },
});
